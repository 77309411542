import * as React from 'react'
import { Alert, Box, Button, Popper, TableRow } from '@mui/material'
import ConfirmDialog from 'components/confirmDialog'
import { Identity } from 'interfaces'
import { useEffect, useState } from 'react'
import { fetchJsonOpts, submitJsonOpts } from 'utils'
import CheckIcon from '@mui/icons-material/Check'
import { Trans, useTranslation } from 'react-i18next'
import { TableCell } from 'components/table'
import {
  appInst,
  disableNinVerification,
  enableIgaCheck,
  verifyGuestExplanationLink,
} from 'appConfig'

interface IdentityLineProps {
  text: string
  identity: Identity | null
  isSponsorForGuest: boolean
  reloadGuest: () => void
  reloadGuests: () => void
}

const IdentityLine = ({
  text,
  identity,
  isSponsorForGuest,
  reloadGuest,
  reloadGuests,
}: IdentityLineProps) => {
  // Make a line with a confirmation button and
  // rejection button if the identity has not been verified,
  // and a retraction button if the identity has been
  if (identity == null) {
    return <></>
  }
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [rejectOpen, setRejectOpen] = useState<boolean>(false)
  const [retractOpen, setRetractOpen] = useState<boolean>(false)
  const [t] = useTranslation('common')
  const verifyIdentity = (id: string) => async () => {
    await fetch(`/api/ui/v1/identity/${id}`, submitJsonOpts('PATCH', {}))
    reloadGuest()
    reloadGuests()
  }
  const rejectIdentity = (id: string) => async () => {
    await fetch(`/api/ui/v1/invite/${id}/recreate`, submitJsonOpts('PATCH', {}))
    reloadGuest()
    reloadGuests()
  }
  const retractIdentity = (id: string) => async () => {
    await fetch(`/api/ui/v1/invite/${id}/recreate`, submitJsonOpts('PATCH', {}))
    reloadGuest()
    reloadGuests()
  }
  const [verifiedBoxAnchor, setVerifiedBoxAnchor] =
    React.useState<null | HTMLElement>(null)
  const verifiedBoxOpen = Boolean(verifiedBoxAnchor)
  const verifiedBoxId = verifiedBoxOpen ? 'verified-popper' : undefined

  const handleVerifiedInfo = (event: React.MouseEvent<HTMLElement>) => {
    setVerifiedBoxAnchor(verifiedBoxAnchor ? null : event.currentTarget)
  }

  const [identityCheckText, setIdentityCheckText] = useState<string | null>(
    null
  )
  const checkIdentity = (id: string) => {
    const igaMatch = fetch(`/api/ui/v1/identitycheck/${id}`, fetchJsonOpts())
      .then((res) => res.json())
      .then((data) =>
        data.match ? `${data.match.first} ${data.match.last}` : null
      )
      .catch(() => 'failure')
    return igaMatch
  }

  const fetchIdentityCheckText = async (id: string) => {
    checkIdentity(id).then((idtxt) => setIdentityCheckText(idtxt))
  }

  const IdentityCheckText = () => {
    let iga
    switch (appInst) {
      case 'uio':
        iga = 'Cerebrum'
        break
      // case 'uit':
      //   iga = "RI"
      //   break
      default:
        iga = 'IGA'
        break
    }
    if (identityCheckText === 'failure') {
      return (
        <>
          <br />
          <br />
          <Alert severity="warning">
            <Trans i18nKey="guestInfo.identityCheck.failure" t={t}>
              (<strong>Warning</strong>): Unable to check if person already
              exists in {{ iga }}.
            </Trans>
          </Alert>
        </>
      )
    }
    if (identityCheckText) {
      return (
        <>
          <br />
          <br />
          <Alert severity="warning">
            <Trans t={t} i18nKey="guestInfo.identityCheck.text">
              (<strong>Warning</strong>): {{ identityCheckText }} already has
              this ID registered to them in {{ iga }}. Please stop if this is
              not the same person.
            </Trans>
          </Alert>
        </>
      )
    }
    return <></>
  }
  const getConfirmDialogText = () => {
    switch (appInst) {
      case 'uio':
        return (
          <Trans
            t={t}
            i18nKey="confirmationDialog.text.uio"
            components={[<a href={verifyGuestExplanationLink || '#'}> </a>]}
          />
        )
      case 'uib':
        return t('confirmationDialog.text.uib')
      default:
        return t('confirmationDialog.text.default')
    }
  }
  const getRejectDialogText = () => {
    const transBuilder = (baseText: string) => (
      <Trans
        i18nKey={`${t(baseText)}<br/><br/>${t(
          'rejectionDialog.text.helperText'
        )}`}
      />
    )
    switch (appInst) {
      case 'uio':
        return transBuilder('rejectionDialog.text.uio')
      case 'uib':
        return transBuilder('rejectionDialog.text.uib')
      default:
        return transBuilder('rejectionDialog.text.default')
    }
  }
  const getRetractDialogText = () => {
    const transBuilder = (baseText: string) => (
      <Trans
        i18nKey={`${t(baseText)}<br/><br/>${t(
          'retractionDialog.text.helperText'
        )}`}
      />
    )
    switch (appInst) {
      case 'uio':
        return transBuilder('retractionDialog.text.uio')
      case 'uib':
        return transBuilder('retractionDialog.text.uib')
      default:
        return transBuilder('retractionDialog.text.default')
    }
  }

  useEffect(() => {
    if (enableIgaCheck && confirmOpen && identityCheckText === null) {
      fetchIdentityCheckText(identity.id)
    }
  }, [confirmOpen])
  return (
    <TableRow>
      <TableCell align="left">{text}</TableCell>
      <TableCell align="left">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
          }}
        >
          <Box>
            {identity ? identity.value : ''}
            {!identity.verified && !identity.value.includes('*') && (
              <>
                <Button
                  aria-label={t('button.verify')}
                  sx={{
                    alignSelf: { xs: 'auto', md: 'flex-end' },
                    marginLeft: { xs: '0rem', md: '1rem' },
                    marginTop: { xs: '0.3rem', md: '0rem' },
                  }}
                  onClick={() => setConfirmOpen(true)}
                  disabled={
                    !identity ||
                    (disableNinVerification &&
                      identity.type === 'norwegian_national_id_number')
                  }
                >
                  {t('button.verify')}
                </Button>
                <ConfirmDialog
                  title={t('confirmationDialog.confirmIdentityTitle')}
                  open={confirmOpen}
                  setOpen={setConfirmOpen}
                  onConfirm={verifyIdentity(identity.id)}
                >
                  {getConfirmDialogText()}
                  <IdentityCheckText />
                </ConfirmDialog>
                <Button
                  aria-label={t('button.verify')}
                  sx={{
                    alignSelf: { xs: 'auto', md: 'flex-end' },
                    marginLeft: { xs: '0rem', md: '1rem' },
                    marginTop: { xs: '0.3rem', md: '0rem' },
                  }}
                  onClick={() => setRejectOpen(true)}
                  disabled={
                    !identity ||
                    (disableNinVerification &&
                      identity.type === 'norwegian_national_id_number') ||
                    !isSponsorForGuest
                  }
                >
                  {t('button.reject')}
                </Button>
                <ConfirmDialog
                  title={t('rejectionDialog.rejectIdentityTitle')}
                  open={rejectOpen}
                  setOpen={setRejectOpen}
                  onConfirm={rejectIdentity(identity.id)}
                >
                  {getRejectDialogText()}
                  <IdentityCheckText />
                </ConfirmDialog>
              </>
            )}
            {identity.verified && (
              <>
                <CheckIcon
                  sx={{
                    fill: (theme) => theme.palette.success.main,
                    verticalAlign: 'text-bottom',
                    border: 0,
                  }}
                  titleAccess={t('guestInfo.verified')}
                />
              </>
            )}
            <Button
              onClick={handleVerifiedInfo}
              sx={{
                alignSelf: { xs: 'auto', md: 'flex-end' },
                marginLeft: { xs: '0rem', md: '1rem' },
                marginTop: { xs: '0.3rem', md: '0rem' },
              }}
            >
              {t('guestInfo.viewInfo')}
            </Button>
            <Popper
              id={verifiedBoxId}
              open={Boolean(verifiedBoxAnchor)}
              anchorEl={verifiedBoxAnchor}
            >
              <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                {identity ? `${t('guestInfo.source')}: ${identity.source}` : ''}
                <br />
                {identity && identity.verified !== ''
                  ? `${t('guestInfo.verifier')}: ${
                      identity.verified === 'manual'
                        ? identity.verified_by
                        : identity.source
                    }`
                  : ''}
              </Box>
            </Popper>
            {identity.verified && (
              <>
                <Button
                  aria-label={t('button.verify')}
                  sx={{
                    alignSelf: { xs: 'auto', md: 'flex-end' },
                    marginLeft: { xs: '0rem', md: '1rem' },
                    marginTop: { xs: '0.3rem', md: '0rem' },
                  }}
                  onClick={() => setRetractOpen(true)}
                  disabled={
                    !identity ||
                    (disableNinVerification &&
                      identity.type === 'norwegian_national_id_number') ||
                    !isSponsorForGuest
                  }
                >
                  {t('button.retract')}
                </Button>
                <ConfirmDialog
                  title={t('retractionDialog.retractIdentityTitle')}
                  open={retractOpen}
                  setOpen={setRetractOpen}
                  onConfirm={retractIdentity(identity.id)}
                >
                  {getRetractDialogText()}
                  <IdentityCheckText />
                </ConfirmDialog>
              </>
            )}
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default IdentityLine
